<div class="px-5">
	@if (userAuthenticated || clientAuthenticated) {
		<div class="flex items-center gap-3 py-4 border-b">
			<!-- user avatar -->
			@if (userAuthenticated) {
				<a routerLink="/user-profile" (click)="closeNav()" class="flex-shrink-0">
					@if (avatar) {
						<img
							class="rounded-full dropbtn"
							alt="User Avatar"
							width="56"
							height="56"
							[ngSrc]="avatar | cdn"
						/>
					} @else {
						<span class="flex items-center flex-shrink-0 ml-4 lg:ml-0">
							<uc-user-icon-svg customStyles="w-14 h-14"></uc-user-icon-svg>
						</span>
					}
				</a>
			}
			<!-- client avatar -->
			@if (clientAuthenticated) {
				<a href="/dashboard" (click)="closeNav()" class="flex-shrink-0">
					@if (logo) {
						<img
							class="rounded-full dropbtn"
							width="56"
							height="56"
							alt="User Avatar"
							[ngSrc]="logo"
						/>
					} @else {
						<span class="flex items-center flex-shrink-0 ml-4 lg:ml-0">
							<uc-user-icon-svg customStyles="w-14 h-14"></uc-user-icon-svg>
						</span>
					}
				</a>
			}
			<!-- generic user -->
			@if (userAuthenticated && firstName) {
				<div class="flex flex-col">
					<a
						routerLink="/user-profile"
						(click)="closeNav()"
						class="text-lg font-semibold leading-7 text-gray-900"
					>
						{{ firstName }} {{ secondName }}
					</a>
					<p class="text-sm leading-5 text-gray-500">
						{{ startYear }} {{ degree }} Student &middot;
						<button (click)="signOut()" class="underline">Logout</button>
					</p>
				</div>
			}
			<!-- university user -->
			@if (clientAuthenticated && uniName) {
				<div class="flex flex-col">
					<a
						href="/dashboard"
						(click)="closeNav()"
						class="text-lg font-semibold leading-7 text-gray-900"
					>
						{{ uniName | ucEntities }}
					</a>
					<p class="text-sm leading-5 text-gray-500">
						<button (click)="signOut()" class="underline">Logout</button>
					</p>
				</div>
			}
		</div>
	}
</div>
<div class="px-5 pt-4 pb-6">
	<ul class="grid grid-cols-2 gap-3">
		<li>
			<a
				routerLink="/select"
				(click)="closeNav(); onProspectusClick()"
				class="subnav-gray"
				>Order Prospectus</a
			>
		</li>
		<li>
			<a
				href="https://onelink.to/unicompare"
				target="_blank"
				rel="noopener"
				(click)="closeNav(); onDownloadAppClick()"
				class="subnav-gray"
				>Download our App</a
			>
		</li>
		<li>
			<a
				routerLink="/degree-quiz"
				(click)="closeNav(); onQuizButtonClick()"
				class="subnav-gray"
				>Course Degree Quiz</a
			>
		</li>
		<li>
			<a
				routerLink="/request"
				(click)="closeNav(); onRequestInfoClick()"
				class="subnav-gray"
				>Request Info</a
			>
		</li>
	</ul>
</div>

@if (!userAuthenticated && !clientAuthenticated) {
	<div class="px-5 pb-6 mt-4">
		<button
			class="block w-full mb-3 secondary-button"
			(click)="showModalPanel(); closeNav(); onLoginClick()"
		>
			Sign In
		</button>
		<a
			class="block blue-button"
			routerLink="/register"
			(click)="closeNav(); onRegisterClick()"
			>Register</a
		>
	</div>
}
