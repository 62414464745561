<div class="relative">
	<!-- gray right panel -->
	<div
		class="absolute top-0 right-0 hidden w-1/3 h-full ml-auto lg:block bg-gray-50"
	></div>

	<div class="relative z-50 grid grid-cols-1 lg:grid-cols-2 lg:container">
		<!-- left panel -->
		<div class="lg:pt-8 lg:pb-11 lg:grid lg:grid-cols-2 lg:gap-9">
			@for (section of data.sublinks; track section) {
				<div>
					<p class="subnav-heading">{{ section.heading }}</p>
					<div class="grid grid-cols-1 subnav-link-padding">
						@for (link of section.links; track link) {
							<uc-nav-link
								[paramKey]="section.param"
								[linkData]="link"
							></uc-nav-link>
						}
					</div>
				</div>
			}
			@if (clearingArticles$ | async; as articles) {
				<div>
					<p class="subnav-heading">Clearing Advice</p>
					@for (link of articles; track link; let last = $last) {
						<div class="subnav-link-padding" [ngClass]="{ 'lg:mb-8': !last }">
							<uc-nav-link [linkData]="link"></uc-nav-link>
						</div>
					}
				</div>
			}
		</div>

		<!-- divider on mobile -->
		<div class="w-full h-px border-t lg:hidden"></div>

		<!-- right panel -->
		@if (clearingUnis$ | async; as unis) {
			<div
				class="py-7 lg:py-0 lg:p-8 lg:pt-8 lg:border-l lg:pb-11 bg-gray-50 side-style"
			>
				<p class="subnav-heading">Recommended Clearing Universities</p>
				@for (card of unis; track card) {
					<uc-nav-card-landscape [data]="card"></uc-nav-card-landscape>
				}
			</div>
		}
	</div>
</div>
