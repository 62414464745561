import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoginGTMService } from '@uc/web/core';
import { BaseNavlinkComponent } from '../base-navlink.component';

@Component({
	selector: 'uc-avatar-user-menu',
	templateUrl: './avatar-user-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarUserMenuComponent extends BaseNavlinkComponent {
	@Input({ required: true }) userAuthenticated!: boolean;
	@Input({ required: true }) clientAuthenticated!: boolean;
	@Input({ required: true }) avatar: string | undefined;
	@Input({ required: true }) logo!: string | undefined;

	constructor(private loginGTMSrv: LoginGTMService) {
		super();
	}

	onLoginClick = () => this.loginGTMSrv.loginClick();
}
