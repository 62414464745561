<div class="flex items-start gap-6 mt-6">
	@if (data.image) {
		@if (!data.external) {
			<a
				[routerLink]="data.href"
				(click)="closeNav()"
				class="relative flex-shrink-0 hidden lg:h-20 lg:block lg:w-36"
			>
				<img [ngSrc]="data.image | cdn" fill alt="" class="absolute nav-image" />
			</a>
		}
		@if (data.external) {
			<a
				[attr.href]="data.href"
				target="_blank"
				rel="noopener sponsored"
				(click)="onClick()"
				class="relative flex-shrink-0 hidden lg:block lg:h-20 lg:w-36"
			>
				<img [ngSrc]="data.image | cdn" fill alt="" class="absolute nav-image" />
			</a>
		}
	}

	<div>
		<p>
			@if (!data.external) {
				<a [routerLink]="data.href" (click)="onClick()" class="navlink-black">{{
					data.heading
				}}</a>
			}
			@if (data.external) {
				<a
					[attr.href]="data.href"
					target="_blank"
					rel="noopener sponsored"
					(click)="onClick()"
					class="navlink-black"
					>{{ data.heading }}</a
				>
			}
		</p>
		<p class="mt-1 mb-3 nav-description">
			{{ data.description }}
			@if (data.noReviews && data.uniId && data.uniName && data.slug) {
				&middot;
				<uc-write-review-btn
					[universityId]="data.uniId"
					[universityName]="data.uniName"
					[universityUrl]="data.slug"
					buttonText="Leave a review"
					(click)="onClick()"
				/>
			}
		</p>
		@if (!data.external) {
			<a [routerLink]="data.href" (click)="onClick()" class="navlink-blue">
				{{ data.title }}
				<uc-arrow-right-v3-svg />
			</a>
		}
		@if (data.external) {
			<a
				[attr.href]="data.href"
				(click)="onClick()"
				target="_blank"
				rel="noopener sponsored"
				class="navlink-blue"
			>
				{{ data.title }}
				<uc-arrow-right-v3-svg />
			</a>
		}
	</div>
</div>
