import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import { NavCard } from '../../models/navigation.models';

@Component({
	selector: 'uc-nav-card-landscape',
	templateUrl: './nav-card-landscape.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavCardLandscapeComponent extends BaseNavlinkComponent {
	@Input() data!: NavCard;
	@Output() linkClicked = new EventEmitter<void>();

	onClick() {
		this.closeNav();
		this.linkClicked.emit();
	}
}
