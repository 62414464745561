import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { clearing } from '../../../static/subnav-clearing.properties';
import {
	getStateNavClearingUnis,
	getStateNavClearingArticles,
} from '../../../data-access/+store/navigation.selectors';

@Component({
	selector: 'uc-subnav-clearing',
	templateUrl: './subnav-clearing.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubnavClearingComponent {
	data = clearing;
	clearingUnis$ = this.store.select(getStateNavClearingUnis);
	clearingArticles$ = this.store.select(getStateNavClearingArticles);

	constructor(private store: Store) {}
}
