import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseNavlinkComponent } from '../base-navlink.component';
import {
	DegreeQuizGTMService,
	DownloadAppGTMService,
	ProspectusGTMService,
	RequestInfoGTMService,
	LoginGTMService,
	RegisterGTMService,
} from '@uc/web/core';

@Component({
	selector: 'uc-secondary-nav-mobile',
	templateUrl: './secondary-nav-mobile.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SecondaryNavMobileComponent extends BaseNavlinkComponent {
	@Input({ required: true }) userAuthenticated!: boolean;
	@Input({ required: true }) clientAuthenticated!: boolean;
	@Input({ required: true }) avatar: string | undefined;
	@Input({ required: true }) firstName: string | undefined;
	@Input({ required: true }) secondName: string | undefined;
	@Input({ required: true }) startYear: number | undefined;
	@Input({ required: true }) degree: string | undefined;
	@Input({ required: true }) logo: string | undefined;
	@Input({ required: true }) uniId: number | undefined;
	@Input({ required: true }) uniName: string | undefined;

	private readonly _componentName = 'Nav';

	constructor(
		private _loginGTMSrv: LoginGTMService,
		private _registerGTMSrv: RegisterGTMService,
		private _prospectusGTMService: ProspectusGTMService,
		private _downloadAppGTMService: DownloadAppGTMService,
		private _degreeQuizGTMSrv: DegreeQuizGTMService,
		private _requestInfoGTMService: RequestInfoGTMService,
	) {
		super();
	}

	onLoginClick = () => this._loginGTMSrv.loginClick();
	onRegisterClick = () => this._registerGTMSrv.registerClick(this._componentName);
	onProspectusClick = () =>
		this._prospectusGTMService.getProspectusClick(this._componentName, null);
	onDownloadAppClick = () => this._downloadAppGTMService.click();
	onQuizButtonClick = () => this._degreeQuizGTMSrv.onQuizButtonClick('default');
	onRequestInfoClick = () =>
		this._requestInfoGTMService.requestInfoClick(this._componentName, null);
}
