<nav aria-label="Secondary" class="bg-gray-100 relative z-[102]">
	<div class="container">
		<ul class="hidden text-xs lg:flex lg:items-center lg:justify-end lg:gap-x-6">
			<li>
				<a
					routerLink="/select"
					(click)="closeNav(); onProspectusClick()"
					class="block py-3 text-gray-500 underline hover:text-gray-800"
					>Order Prospectus</a
				>
			</li>
			<li>
				<a
					routerLink="/degree-quiz"
					(click)="closeNav(); onQuizButtonClick()"
					class="block py-3 text-gray-500 underline hover:text-gray-800"
					>Course Degree Quiz</a
				>
			</li>
			<li>
				<a
					href="https://onelink.to/unicompare"
					target="_blank"
					rel="noopener"
					(click)="closeNav(); onDownloadAppClick()"
					class="block py-3 text-gray-500 underline hover:text-gray-800"
					>Download our App</a
				>
			</li>
			@if (!userAuthenticated && !clientAuthenticated) {
				<li>
					<button
						(click)="showModalPanel(); closeNav(); onLoginClick()"
						class="block py-3 text-gray-500 underline hover:text-gray-800"
					>
						Sign In
					</button>
				</li>
				<li>
					<a
						routerLink="/register"
						(click)="closeNav(); onRegisterClick()"
						class="block py-3 text-gray-500 underline hover:text-gray-800"
						>Register</a
					>
				</li>
			}
			@if (userAuthenticated) {
				<li>
					<a
						routerLink="/user-profile"
						(click)="closeNav()"
						class="block py-3 text-gray-500 underline hover:text-gray-800"
						>Edit Profile</a
					>
				</li>
			}
			@if (clientAuthenticated) {
				<li>
					<a
						href="/dashboard"
						(click)="closeNav()"
						class="block py-3 text-gray-500 underline hover:text-gray-800"
						>Dashboard</a
					>
				</li>
			}
		</ul>
	</div>
</nav>
