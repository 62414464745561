import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { unis } from '../../../static/subnav-unis.properties';
import { getStateNavUnis } from '../../../data-access/+store/navigation.selectors';

@Component({
	selector: 'uc-subnav-unis',
	templateUrl: './subnav-unis.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubNavUnisComponent {
	data = unis;
	unis$ = this.store.select(getStateNavUnis);

	constructor(private store: Store) {}
}
